import React from 'react'
import { Grid, Button, Header } from 'semantic-ui-react'
import TimeZone from '../TimeZone'

const TimeZoneConfirmation = ({ project, setPage }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Header>Confirm Your Time Zone</Header>

          <Header as="h4">
            Please confirm your time zone to ensure accurate scheduling
            <Header.Subheader>
              {`Click the dropdown, start typing the major city where you are
              located—such as "New" for "New York" or "New Jersey"—and select
              the correct one from the list.`}
            </Header.Subheader>
          </Header>
          <TimeZone project={project} />
          <br />
          <br />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="right">
          <Button primary onClick={() => setPage('SCHEDULE_TIME')}>
            Next
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default TimeZoneConfirmation
