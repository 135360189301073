import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Grid, Header, Form, Button } from 'semantic-ui-react'
import DataContext from '../contexts/DataContext'
import OrganizationDropdown from './OrganizationDropdown'
import { UPDATE_EXPERT } from '../graphql/expert'

const WorkConfirmation = ({ onNext }) => {
  const { advisor } = useContext(DataContext)
  const [sameWork, setSameWork] = useState(null)
  const [title, setTitle] = useState(advisor.expert.title)
  const [organization, setOrganization] = useState(advisor.expert.organization)

  const [updateExpert, { loading }] = useMutation(UPDATE_EXPERT)

  const next = () => {
    updateExpert({
      variables: {
        input: {
          title,
          organization: organization.id,
        },
      },
      onCompleted: onNext,
    })
  }

  return (
    <Grid id="work-confirmation">
      <Grid.Row>
        <Grid.Column>
          <Header as="h5">
            {'Please confirm: Are you still working at '}
            <b>{advisor.expert.organization.name}</b>
            {' as a '}
            <b>{advisor.expert.title}</b>
          </Header>
          <Form>
            <Form.Radio
              label="Yes, my job details are correct."
              checked={sameWork === 'Yes'}
              onChange={() => setSameWork('Yes')}
            />
            <Form.Radio
              label="No, I need to update my information."
              checked={sameWork === 'No'}
              onChange={() => setSameWork('No')}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
      {sameWork === 'No' && (
        <Grid.Row>
          <Grid.Column>
            <Header as="h5">
              Please update your current job details to ensure we have the most
              accurate information about you
            </Header>
            <Form>
              <Form.Input
                label="Job title"
                value={title}
                onChange={(e, { value }) => setTitle(value)}
              />
              <OrganizationDropdown
                organization={organization}
                setOrganization={setOrganization}
              />
            </Form>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column>
          <Button
            primary
            loading={loading}
            disabled={sameWork === null || loading}
            floated="right"
            onClick={next}
          >
            Next
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default WorkConfirmation
