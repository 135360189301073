import React from 'react'
import { Route } from 'react-router-dom'
import { Grid, Header } from 'semantic-ui-react'
import Logobar from '../Logobar'

const PublicProjectRoute = ({ path, component: Component }) => (
  <>
    <Logobar />
    <Route path={path}>
      <div id="public-project-landing">
        <Grid stackable>
          <Grid.Row>
            <Grid.Column computer={2} only={'computer'} />
            <Grid.Column computer={10}>
              <Component />
            </Grid.Column>
            <Grid.Column computer={4}>
              <div id="contact">
                <Header sub>Questions?</Header>
                <span id="contact-text">
                  Contact help@dexterexperts.com <br />
                  for any questions
                </span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Route>
  </>
)

export default PublicProjectRoute
