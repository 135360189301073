import React, { useState } from 'react'
import Autocomplete from './Organizations/Autocomplete'
import NewOrganizationModal from './Organizations/NewOrganizationModal'

const OrganizationDropdown = ({ organization, setOrganization }) => {
  const [orgQuery, setOrgQuery] = useState(
    organization.name && organization.location
      ? `${organization.name} - ${organization.location.city} ${organization.location.country}`
      : ''
  )
  const [defaultQuery, setDefaultQuery] = useState('')
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <NewOrganizationModal
        defaultQuery={defaultQuery}
        done={(query, organizationId) => {
          setOrgQuery(query)
          setOrganization((prev) => ({ ...prev, id: organizationId }))
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <Autocomplete
        required
        width={8}
        label="Organization"
        placeholder="Enter your organization"
        context="Organization"
        readOnly={organization.id}
        value={orgQuery}
        onChange={(value) => {
          setOrgQuery(value)
          setOrganization((prev) => ({ ...prev, id: null }))
        }}
        onSelect={({ organizationId }) => {
          setOrganization((prev) => ({ ...prev, id: organizationId }))
        }}
        openCreateModal={() => {
          setOpenModal(true)
          setDefaultQuery(orgQuery)
          setOrgQuery('')
        }}
      />
    </>
  )
}

export default OrganizationDropdown
