import React, { useContext } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import cleanTypenameLink from './utils/cleanTypenameLink'
import { createUploadLink } from 'apollo-upload-client'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import Payment from './pages/Payment'
import Projects from './pages/Projects'
import Profile from './pages/Profile'
import Referral from './pages/Referral'
import Landing from './pages/Landing'
import PublicProjectLanding from './pages/PublicProject/PublicProjectLanding'
import PublicProjectAuthenticate from './pages/PublicProject/PublicProjectAuthenticate'
import PublicProjectRegister from './pages/PublicProject/PublicProjectRegister'
import Meeting from './pages/Meeting'
import UnverifiedEmail from './pages/UnverifiedEmail'
import ScrollToTop from './ScrollToTop'
import ExpertRoute from './components/ExpertRoute'
import { DataProvider } from './contexts/DataContext'
import { MessageProvider } from './contexts/MessageContext'
import AuthContext from './contexts/AuthContext'
import PublicProjectRoute from './components/PublicProject/PublicProjectRoute'
import PublicProjectUnqualified from './pages/PublicProject/PublicProjectUnqualified'
import NavbarLayout from './components/NavbarLayout'

const guestUserPages = (
  <Switch>
    <PublicProjectRoute
      path="/landing/projects/:id"
      component={PublicProjectLanding}
    />
    <PublicProjectRoute
      path="/landing/authenticate"
      component={PublicProjectAuthenticate}
    />
    <PublicProjectRoute
      path="/landing/invite-to-dexter"
      component={PublicProjectUnqualified}
    />
    <Route path="/" component={Landing} />
  </Switch>
)

const loggedInUserPages = (
  <DataProvider>
    <Switch>
      <PublicProjectRoute
        path="/landing/projects/:id"
        component={PublicProjectLanding}
      />
      <PublicProjectRoute
        path="/landing/register"
        component={PublicProjectRegister}
      />
      <ExpertRoute path="/referral" component={Referral} />
      <ExpertRoute path="/profile/:page" component={Profile} />
      <ExpertRoute path="/profile" component={Profile} />
      <ExpertRoute path="/projects/:page" component={Projects} />
      <ExpertRoute path="/projects" component={Projects} />
      <ExpertRoute path="/meetings/:id" component={Meeting} />
      <ExpertRoute path="/payment" component={Payment} />
      <ExpertRoute path="/payment/:uri" component={Payment} />
      <ExpertRoute path="/dashboard" component={Dashboard} />
      <Route
        path="/register"
        render={() => (
          <NavbarLayout>
            <Register />
          </NavbarLayout>
        )}
      />
      <Route path="/" render={() => <Redirect to="/dashboard" />} />
    </Switch>
  </DataProvider>
)

const unverifiedUserPages = (
  <Switch>
    <Route path="/" component={UnverifiedEmail} />
  </Switch>
)

const Routes = () => {
  const { token, auth0User } = useContext(AuthContext)
  const authLink = setContext(async (_, { headers }) => ({
    headers: { ...headers, ...(token && { authorization: token }) },
  }))

  const apolloClient = new ApolloClient({
    link: from([
      authLink,
      cleanTypenameLink,
      createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_BASE_URL,
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Project: {
          merge: true,
          fields: {
            calendar: { merge: false },
          },
        },
      },
    }),
  })

  const getPages = () => {
    if (auth0User?.email_verified) {
      return loggedInUserPages
    }
    if (auth0User && !auth0User.email_verified) {
      return unverifiedUserPages
    }
    return guestUserPages
  }

  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ScrollToTop />
        <MessageProvider>{getPages()}</MessageProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default Routes
