import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Header, Button } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import { SINGLE_PROJECT_PUBLIC_QUERY } from '../../graphql/project'
import Loading from '../../components/Loading'
import PublicProjectInfo from '../../components/PublicProject/PublicProjectInfo'
import FAQ from '../../components/PublicProject/FAQ'
import NotFoundPage from '../NotFoundPage'
import AuthContext from '../../contexts/AuthContext'
import DataContext from '../../contexts/DataContext'
import ExistingResponseAlert from '../../components/PublicProject/ExistingResponseAlert'
import QualificationQuestions from '../../components/QualificationQuestions'

const Landing = ({ project }) => {
  const history = useHistory()
  const { token } = useContext(AuthContext)
  const dataContext = useContext(DataContext)
  let advisor = null
  let hasResponded = false

  if (dataContext) {
    advisor = dataContext.advisor
    hasResponded = advisor?.jobs.find(
      (job) =>
        job.project.id === project.id &&
        !['Pending Scheduling', 'Client Reschedule'].includes(
          job.overall_status
        )
    )
  }

  useEffect(() => {
    MixpanelTracker.trackViewPublicProjectLanding(
      advisor,
      project,
      hasResponded
    )
  }, [advisor, hasResponded, project])

  const setQualificationResponses = (response) => {
    sessionStorage.setItem('qualification', JSON.stringify(response))
  }
  const onNext = () => {
    sessionStorage.setItem('publicProject', JSON.stringify(project))
    history.push(`/landing/${token ? 'register' : 'authenticate'}`)
  }

  if (hasResponded) return <ExistingResponseAlert project={project} />

  return (
    <div id="public-project-layout">
      <div id="header">
        <Header size="small">
          You have been invited to a Dexter consulting project
        </Header>
      </div>
      <FAQ />
      <div id="about-project">
        <Header sub>About the project</Header>
        <Header size="medium">{project.title}</Header>
        <PublicProjectInfo project={project} />
      </div>
      {project.qualificationQuestions.length > 0 ? (
        <QualificationQuestions
          updateQualificationResponses={setQualificationResponses}
          onSubmit={onNext}
          project={project}
        />
      ) : (
        <div id="public-project-btn">
          <Button
            className="primary-btn"
            primary
            onClick={() => {
              setQualificationResponses([])
              onNext()
            }}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  )
}

const PublicProjectLanding = () => {
  const { id } = useParams()

  const { loading, error, data } = useQuery(SINGLE_PROJECT_PUBLIC_QUERY, {
    variables: { id },
  })

  if (loading) return <Loading />
  if (error || data.project.status !== 'Active') {
    return (
      <NotFoundPage
        message={
          <>
            The project you are looking for is not available at the moment.
            <div>
              Please check again later or contact help@dexterexperts.com for any
              questions.
            </div>
          </>
        }
      />
    )
  }

  return <Landing project={data.project} />
}

export default PublicProjectLanding
