import React from 'react'
import { Message, Header } from 'semantic-ui-react'
import General from '../Forms/General'
import Professional from '../Forms/Professional'

const ExpertRegisterForm = ({ generalRef, professionalRef, message }) => (
  <div className="content-block">
    {message && (
      <Message error header="Empty fields detected!" content={message} />
    )}
    <Header sub>About you</Header>
    <div className="form">
      <span>
        Please provide the below information help us learn more about you before
        the engagement
      </span>
      <General
        isRegister={true}
        skipTimeZone={true}
        skipHeader={true}
        ref={generalRef}
      />
      <Professional
        skipHourlyRate={true}
        skipHeader={true}
        ref={professionalRef}
      />
    </div>
  </div>
)

export default ExpertRegisterForm
