import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Grid, Button, Placeholder } from 'semantic-ui-react'
import { LOAD_ME_QUERY } from '../../../graphql/user'
import { PROJECT_CALENDAR } from '../../../graphql/project'
import { SCHEDULE_MEETING, RESCHEDULE_MEETING } from '../../../graphql/schedule'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import MessageContext from '../../../contexts/MessageContext'
import DataContext from '../../../contexts/DataContext'
import MeetingTimeView from './MeetingTimeView'

const SelectMeetingTime = ({ job, setPage }) => {
  const { advisor } = useContext(DataContext)
  const { setAlertMsg } = useContext(MessageContext)

  const [loader, setLoader] = useState(false)
  const { loading, data, refetch } = useQuery(PROJECT_CALENDAR, {
    variables: { id: job.project.id },
  })
  const [selectedTime, setSelectedTime] = useState('')

  const [updateJob] = useMutation(UPDATE_JOB_MUTATION)

  const [rescheduleMeeting, { loading: rescheduling }] =
    useMutation(RESCHEDULE_MEETING)
  const [scheduleMeeting, { loading: processing }] = useMutation(
    SCHEDULE_MEETING,
    {
      refetchQueries: [{ query: LOAD_ME_QUERY }],
      onCompleted: () => {
        setPage('SCHEDULE_CONFIRMATION')
        setLoader(false)
      },
      onError: (error) => {
        if (
          error.message ===
          'This meeting could not be scheduled due to insufficient credits'
        ) {
          return setAlertMsg({
            title: 'Failed to accept the invitation',
            content:
              'This project is currently on hold. Please check again after some time or contact us at help@dexterexperts.com for more information.',
            onClose: () => refetch(),
          })
        }
        setLoader(false)
        setAlertMsg({
          title: 'Error',
          content: error.message,
          onClose: () => refetch(),
        })
      },
    }
  )

  const onReschedule = () => {
    rescheduleMeeting({
      variables: { jobId: job.id },
      update: () => onSchedule(),
    })
  }

  const onSchedule = async () => {
    if (selectedTime === '') {
      return setAlertMsg({
        title: 'Error',
        content: 'You need to select a time slot',
      })
    }
    setLoader(true)

    // Save the job.qualificationResponses to database
    // This qualificationResponses field is attached to the job that passed as a prop from regular scheduling workflow
    if (job.qualificationResponses.length > 0) {
      await updateJob({
        variables: {
          userId: advisor.id,
          projectId: job.project.id,
          job: {
            qualificationResponses: job.qualificationResponses,
          },
        },
      })
    }

    await scheduleMeeting({
      variables: {
        jobId: job.id,
        startTime: selectedTime,
      },
    })
    setPage('SCHEDULE_CONFIRMATION')
  }
  if (loading)
    return (
      <Placeholder>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    )
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <MeetingTimeView
            project={data.project}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="right">
          <Button
            primary
            loading={loader || processing || rescheduling}
            disabled={loader || processing || rescheduling}
            onClick={job.meetingAt ? onReschedule : onSchedule}
          >
            Submit
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default SelectMeetingTime
