import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import Confirmation from './Confirmation'
import AcceptWorkflow from './AcceptWorkflow'

const AcceptModal = ({ project, job, children }) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  // TODO: Remove full service after fully merged DBC as a client
  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={onClose}
      trigger={children}
    >
      <Modal.Header>{project.title}</Modal.Header>
      <Modal.Content>
        {project.service_type === 'Full-Service' ? (
          <Confirmation project={project} job={job} />
        ) : (
          <AcceptWorkflow project={project} job={job} onClose={onClose} />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default AcceptModal
