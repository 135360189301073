import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Grid, Image, Segment, Button } from 'semantic-ui-react'
import AuthContext from '../contexts/AuthContext'
import { LOGOUT } from '../graphql/user'
import DataContext from '../contexts/DataContext'

const LogoutBtn = () => {
  const { advisor } = useContext(DataContext)
  const { logout: authLogout } = useContext(AuthContext)
  const [logout] = useMutation(LOGOUT)
  return (
    <Button
      basic
      color="red"
      size="small"
      floated="right"
      onClick={() => {
        logout({ variables: { id: advisor.id } }).then(() => authLogout())
      }}
    >
      Sign out
    </Button>
  )
}

const Logobar = () => {
  const { auth0User } = useContext(AuthContext)
  return (
    <Grid>
      <Grid.Row>
        <Segment clearing attached="top">
          <Link
            to={{ pathname: 'https://www.dexterexperts.com' }}
            target="_blank"
            style={{ display: 'inline-block' }}
          >
            <Image
              size="small"
              src={`${process.env.REACT_APP_AWS_S3_URL}/dexterlogo_trans.png`}
              style={{ padding: 15 }}
            />
          </Link>
          {auth0User && <LogoutBtn />}
        </Segment>
      </Grid.Row>
    </Grid>
  )
}

export default Logobar
