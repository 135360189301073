import React, { useContext, useRef, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Header, Button } from 'semantic-ui-react'
import moment from 'moment-timezone'
import { SEND_EMAIL_MUTATION } from '../../graphql/sendgrid'
import { CREATE_EXPERT } from '../../graphql/expert'
import { LOAD_ME_QUERY, UPDATE_USER } from '../../graphql/user'
import { SCHEDULE_MEETING } from '../../graphql/schedule'
import { UPDATE_JOB_MUTATION } from '../../graphql/job'
import PublicProjectInfo from './PublicProjectInfo'
import ExpertRegisterForm from './ExpertRegisterForm'
import MixpanelTracker from '../../utils/MixpanelTracker'
import MessageContext from '../../contexts/MessageContext'
import { getWelcomeEmail } from '../../components/Emails/WelcomeEmail'
import DataContext from '../../contexts/DataContext'

const ConfirmRegister = ({ project, jobData, setPage }) => {
  const history = useHistory()
  const { advisor, activeProjects } = useContext(DataContext)
  const { setAlertMsg } = useContext(MessageContext)

  const isExistingExpert = advisor.expert !== null

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const generalRef = useRef(null)
  const professionalRef = useRef(null)

  const [createExpert] = useMutation(CREATE_EXPERT)
  const [updateUser] = useMutation(UPDATE_USER)
  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
  const [updateJob] = useMutation(UPDATE_JOB_MUTATION)
  const [scheduleMeeting] = useMutation(SCHEDULE_MEETING, {
    refetchQueries: [{ query: LOAD_ME_QUERY }],
  })

  useEffect(() => {
    MixpanelTracker.trackViewPublicProjectRegisterForm()
  }, [])

  const { meetingAt, qualificationResponses } = jobData

  const removeSessionStorage = () => {
    sessionStorage.removeItem('publicProject')
    sessionStorage.removeItem('qualification')
  }

  const completeRegister = (job, error) => {
    let state = null
    if (error) {
      setAlertMsg(error)
    }
    if (job) {
      state = {
        type: project.type,
        showNextStepsModal: activeProjects.length === 0,
        showPreInterviewModal: !!job.project.preInterviewLink,
        job,
      }
    }
    removeSessionStorage()
    history.push('/projects/active', state)
  }

  const createNewExpert = async () => {
    const { firstName, lastName, salutation, country } =
      generalRef.current.state
    const { title, organization, linkedinProfile } =
      professionalRef.current.state

    if (firstName === '') {
      setMessage('Please fill out your first name.')
      return false
    }
    if (lastName === '') {
      setMessage('Please fill out your last name.')
      return false
    }
    if (country === '') {
      setMessage('Please select your country.')
      return false
    }
    if (title === '') {
      setMessage('Please fill out your title.')
      return false
    }
    if (!organization) {
      setMessage('Please select your organization.')
      return false
    }

    return new Promise((resolve) => {
      createExpert({
        variables: {
          profile: {
            first_name: firstName,
            last_name: lastName,
            salutation,
            country,
            title,
            organization,
            linkedin_profile: linkedinProfile,
            hourlyRate: parseInt(project.compensationType.params[0]),
          },
          notifications: {
            status: 'Active',
            reminder_notify: true,
            payment_notify: true,
          },
        },
        onCompleted: () => {
          updateUser({
            variables: { input: { firstName, lastName } },
            update: (cache, { data }) => {
              cache.writeQuery({
                query: LOAD_ME_QUERY,
                data: { me: data.updateUser },
              })
            },
          })
          sendEmail({
            variables: {
              input: {
                from: {
                  email: 'help@dexterexperts.com',
                  name: 'Dexter Expert Network',
                },
                to: { email: advisor.email },
                subject: 'Welcome to Dexter | Thanks for Joining!',
                text: 'Welcome to Dexter and Thanks for Joining',
                html: getWelcomeEmail(`${firstName} ${lastName}`),
              },
            },
          })
          resolve(true)
        },
      })
    })
  }

  const createJob = async () => {
    return new Promise((resovle) => {
      const jobInput = {
        invite_status: 'Invited',
        invitedAt: new Date(),
        ...(qualificationResponses.length > 0 && {
          qualificationResponses,
        }),
      }
      if (project.type === 'Survey') {
        jobInput.response_status = 'Accepted'
        jobInput.overall_status = 'Scheduled'
        jobInput.responseAt = new Date()
      }
      updateJob({
        variables: {
          userId: advisor.id,
          projectId: project.id,
          job: jobInput,
        },
        onCompleted: ({ updateJob }) => {
          MixpanelTracker.trackPublicProjectLandingCreateJob(updateJob.user)
          if (project.type === 'Interview') {
            scheduleMeeting({
              variables: {
                jobId: updateJob.id,
                startTime: meetingAt,
              },
              onCompleted: (data) => resovle(data.scheduleMeeting),
            })
          } else {
            resovle(updateJob.user.jobs.find((el) => el.id === updateJob.id))
          }
        },
      })
    })
  }

  const getErrorMessage = (errMessage) => {
    let error = {
      title: 'Something went wrong. Failed to accept the invitation',
      content:
        'Please try again and contact us at help@dexterexperts.com if the issue persists.',
    }
    if (
      errMessage ===
        'This meeting could not be scheduled due to insufficient credits' ||
      errMessage === 'No more invites left'
    ) {
      error = {
        title: 'Failed to accept the invitation',
        content:
          'This project is currently on hold. Please try again in 24 hours and contact us at help@dexterexperts.com if the issue persists.',
      }
    }
    return error
  }

  const submit = async () => {
    setMessage(null)
    setLoading(true)
    try {
      if (!isExistingExpert) {
        const expertCreated = await createNewExpert()
        if (!expertCreated) {
          return
        }
      }
      const newJob = await createJob()
      completeRegister(newJob, null)
    } catch (err) {
      const error = getErrorMessage(err.message)
      completeRegister(null, error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id="public-project-landing">
      <div className="content-block">
        Please review the details below and hit &quot;Submit&quot; to{' '}
        {project.type === 'Interview'
          ? ' schedule your meeting'
          : ' book your engagement'}
      </div>
      <div className="content-block">
        <Header size="medium">{project.title}</Header>
        {project.type === 'Interview' && (
          <Header size="small">
            {moment.tz(meetingAt, advisor.timeZone).format('llll z')}
          </Header>
        )}
        <PublicProjectInfo
          project={project}
          qualificationResponses={qualificationResponses}
        />
      </div>
      {!isExistingExpert && (
        <ExpertRegisterForm
          generalRef={generalRef}
          professionalRef={professionalRef}
          message={message}
        />
      )}
      <div
        className={`public-project-form-btn ${
          project.type === 'Interview' ? 'double-btn' : 'single-btn'
        }`}
      >
        {project.type === 'Interview' && (
          <Button
            onClick={() => setPage('SCHEDULE')}
            id="back"
            icon="chevron left"
            loading={loading}
            disabled={loading}
          />
        )}
        <Button
          primary
          onClick={submit}
          loading={loading}
          disabled={loading}
          id="schedule"
        >
          Submit
        </Button>
      </div>
    </div>
  )
}

export default ConfirmRegister
