import React, { useState, useContext, useRef } from 'react'
import ScheduleContext from '../../../contexts/ScheduleContext'
import WorkConfirmation from '../../WorkConfirmation'
import QualificationQuestions from '../../QualificationQuestions/'
import Confirmation from './Confirmation'

const AcceptWorkflow = ({ project, job, onClose }) => {
  const { setScheduler } = useContext(ScheduleContext)
  const [page, setPage] = useState('WORK_CONFIRMATION')
  const qualificationResponses = useRef([])

  const setQualificationResponses = (newResponse) => {
    qualificationResponses.current = newResponse
  }

  const onNext = () => {
    if (project.type === 'Interview') {
      onClose()
      setScheduler({
        ...job,
        qualificationResponses: qualificationResponses.current,
      })
    } else {
      setPage('CONFIRMATION')
    }
  }

  const PAGES = {
    WORK_CONFIRMATION: (
      <WorkConfirmation
        onNext={() => {
          if (
            project.qualificationQuestions.length === 0 ||
            job.qualificationResponses.length > 0
          ) {
            onNext()
          } else {
            setPage('QUALIFICATION_QUESTIONS')
          }
        }}
      />
    ),
    QUALIFICATION_QUESTIONS: (
      <QualificationQuestions
        project={project}
        updateQualificationResponses={setQualificationResponses}
        onSubmit={onNext}
      />
    ),
    CONFIRMATION: (
      <Confirmation
        project={project}
        job={{
          ...job,
          qualificationResponses: qualificationResponses.current,
        }}
      />
    ),
  }

  return PAGES[page]
}

export default AcceptWorkflow
