import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'semantic-ui-react'
import DataContext from '../../contexts/DataContext'
import ScheduleConfirmation from './ScheduleConfirmation'
import PreinterviewModal from '../Modals/PreinterviewModal'
import TimeZoneConfirmation from './TimeZoneConfirmation'
import ScheduleTime from './ScheduleTime'

const Schedule = ({ job, onClose }) => {
  const history = useHistory()
  const { advisor } = useContext(DataContext)

  const [page, setPage] = useState('TIMEZONE_CONFIRMATION')

  const scheduleComplete = () => {
    history.replace('/projects/active')
    onClose()
  }

  const PAGES = {
    TIMEZONE_CONFIRMATION: (
      <TimeZoneConfirmation project={job.project} setPage={setPage} />
    ),
    SCHEDULE_TIME: <ScheduleTime job={job} setPage={setPage} />,
    SCHEDULE_CONFIRMATION: (
      <ScheduleConfirmation
        viewer={advisor}
        job={job}
        setPage={setPage}
        onClose={scheduleComplete}
      />
    ),
    PREINTERVIEW: (
      <PreinterviewModal project={job.project} onClose={scheduleComplete} />
    ),
  }

  return (
    <Modal closeIcon open onClose={onClose}>
      <Modal.Header>{job.project.title}</Modal.Header>
      <Modal.Content>{PAGES[page]}</Modal.Content>
    </Modal>
  )
}

export default Schedule
