import React, { useContext } from 'react'
import { Header, List, Button } from 'semantic-ui-react'
import moment from 'moment-timezone'
import TimeZone from '../../TimeZone'
import DataContext from '../../../contexts/DataContext'

const TimeSlotList = ({
  project,
  currentDate,
  selectedTime,
  setSelectedTime,
}) => {
  const { advisor } = useContext(DataContext)

  const today = project.calendar
    ? project.calendar.filter(
        (day) =>
          day.date ===
          moment.tz(currentDate, advisor.timeZone).format('MM/DD/YYYY')
      )[0]
    : null

  const todayHeader = moment
    .tz(currentDate, advisor.timeZone)
    .format('dddd MMM Do, YYYY')

  const entrylist = today
    ? today.entries.filter((entry) => entry.status === 'Available')
    : []

  entrylist.sort((a, b) => a.startTime - b.startTime)

  return (
    <>
      <Header as="h4">Your timezone</Header>
      <TimeZone project={project} />
      <Header size="medium">{todayHeader}</Header>
      <List>
        {entrylist.length > 0
          ? entrylist.map((entry, index) => {
              return (
                <List.Item key={index}>
                  <Button
                    fluid
                    primary
                    basic={selectedTime !== entry.startTime}
                    onClick={() => setSelectedTime(entry.startTime)}
                  >
                    {moment.tz(entry.startTime, advisor.timeZone).format('LT')}
                  </Button>
                </List.Item>
              )
            })
          : '(No available time)'}
      </List>
    </>
  )
}

export default TimeSlotList
