import React, {
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Header, Form, Input, Message, Icon } from 'semantic-ui-react'
import DataContext from '../../contexts/DataContext'
import OrganizationDropdown from '../OrganizationDropdown'

const Professional = (props, ref) => {
  const { advisor } = useContext(DataContext)

  const [linkedinProfile, setLinkedinProfile] = useState(
    advisor.expert?.linkedin_profile || ''
  )
  const [title, setTitle] = useState(advisor.expert?.title || '')
  const [organization, setOrganization] = useState(
    advisor.expert?.organization || {}
  )

  const [hourlyRate, setHourlyRate] = useState(advisor.expert?.hourlyRate || 0)

  useImperativeHandle(ref, () => {
    return {
      state: {
        linkedinProfile,
        title,
        organization: organization.id,
        hourlyRate,
      },
    }
  })

  return (
    <div id="professional-section">
      {!props.skipHeader && <Header as="h4">Professional Information</Header>}
      <div className="content">
        <Form>
          <Form.Group>
            <Form.Input
              required
              width={8}
              label="Job Title"
              placeholder="Job Title"
              onChange={(e, { value }) => setTitle(value)}
              value={title}
            />
            <OrganizationDropdown
              organization={organization}
              setOrganization={setOrganization}
            />
          </Form.Group>
          <Form.Input
            label="LinkedIn"
            placeholder="https://"
            onChange={(e, { value }) => setLinkedinProfile(value)}
            value={linkedinProfile}
          />
          {!props.skipHourlyRate && (
            <>
              <Form.Field required>
                <label>Hourly rate for consultation</label>
                <div className="ui right labeled input">
                  <label htmlFor="hourly_rate_field" className="ui label">
                    $
                  </label>
                  <Input
                    type="number"
                    placeholder="300"
                    onChange={(e, { value }) => setHourlyRate(value)}
                    value={hourlyRate}
                    min="0"
                    style={{
                      display: 'inline',
                      width: '20%',
                    }}
                    id="hourly_rate_field"
                  />
                  <div className="ui basic label">USD</div>
                </div>
              </Form.Field>
              <Message icon id="iv-hourly-rate-message">
                <Icon name="info circle" id="iv-hourly-rate-message-icon" />
                <Message.Content>
                  <p>
                    A typical Dexter expert earns $200 - $300 per hour for their
                    insights.
                  </p>
                </Message.Content>
              </Message>
            </>
          )}
        </Form>
      </div>
    </div>
  )
}

export default forwardRef(Professional)
