import React, { useState, createContext } from 'react'
import Schedule from '../components/Schedule'

const ScheduleContext = createContext({ setScheduler: null })
export const ScheduleProvider = ({ children }) => {
  const [scheduler, setScheduler] = useState(null)
  return (
    <ScheduleContext.Provider value={{ setScheduler }}>
      {scheduler && (
        <Schedule job={scheduler} onClose={() => setScheduler(null)} />
      )}
      {children}
    </ScheduleContext.Provider>
  )
}

export default ScheduleContext
