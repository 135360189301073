import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Loader, Header, Button, Grid, Message } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import MeetingTimeView from '../Schedule/ScheduleTime/MeetingTimeView'
import { PROJECT_CALENDAR } from '../../graphql/project'

const Schedule = ({ project, updateMeetingAt, setPage }) => {
  const [message, setMessage] = useState(null)
  const [meetingAt, setMeetingAt] = useState(null)
  const { loading, error, data } = useQuery(PROJECT_CALENDAR, {
    variables: { id: project.id },
  })

  useEffect(() => {
    MixpanelTracker.trackViewPublicProjectSchedule()
  }, [])

  if (loading) return <Loader active inline />
  if (error) return null

  const handleNext = () => {
    setMessage(null)
    if (!meetingAt)
      return setMessage('You must select a time slot for your meeting')
    updateMeetingAt(meetingAt)
    setPage('CONFIRM_REGISTER')
  }

  return (
    <div id="public-landing-schedule">
      <Header size="medium">{`Select a meeting time for ${project.title}`}</Header>
      <div id="scheduler">
        {message && <Message content={message} negative />}
        <MeetingTimeView
          selectedTime={meetingAt}
          setSelectedTime={setMeetingAt}
          project={data.project}
        />
      </div>

      <Grid stackable>
        <Grid.Column width={11} />
        <Grid.Column width={5}>
          <div id="public-project-btn">
            <Button primary onClick={handleNext}>
              Next
            </Button>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default Schedule
