export const ORGANIZATION_MODEL = `
  id
  name
  type
  size
  location {
    city
    country
  }
  url
`

export const PROJECT_MODEL = `
  id
  team {
    id
    name
  }
  title
  description
  type
  status
  service_type
  calendly_link
  qualificationQuestions {
    id
    question
    questionType
    answerChoices
    otherIncluded
  }
  owners {
    id
    email
  }
  referable
  referrals {
    expert_name
    expert_email
    referer_name
    referer_email
    referer_linkedin
  }
  surveyLink
  start_date
  end_date
  offerRate
  preInterviewLink
`

export const JOB_MODEL = `
  id
  user {
    id
    email
    fullName
    firstName
    lastName
  }
  project {
    id
    title
    status
    preInterviewLink
  }
  invite_status
  response_status
  overall_status
  payment {
    amount
    method
    createdAt
  }
  project_rating
  invitedAt
  responseAt
  completedAt
  meetingAt
  qualtricsContact {
    surveyLink
  }
  qualificationResponses {
    question
    response
  }
`

export const EXPERT_MODEL = `
  id
  fullName
  first_name
  last_name
  salutation
  description
  phone
  gender
  yearOfBirth
  education
  ethnicity
  language
  veteran_status
  disability_status
  title
  country
  profile_picture
  functions
  organization {
    ${ORGANIZATION_MODEL}
  }
  tags
  internal_tags
  medical_specialties
  hourlyRate
  linkedin_profile
  technology_tags
  nps {
    score
    date
  }
  referrals {
    emails
    date
  }
  status
  tagSuggestions {
    tag
    category
  }
  stripe_account
  reminder_notify
  payment_notify
`

export const USER_MODEL = `
  id
  email
  firstName
  lastName
  fullName
  timeZone
  expert {
    ${EXPERT_MODEL}
  }
  jobs {
    ${JOB_MODEL}
    zoomMeeting {
      meetingLink
    }
  }
`
