import React, { useState } from 'react'
import { Header, List, Accordion, Icon } from 'semantic-ui-react'
import { FAQS } from '../../constants/faqs'

const FAQ = () => {
  const [showFAQs, setShowFAQs] = useState(false)
  return (
    <div id="faqs">
      <Accordion>
        <Accordion.Title
          active={showFAQs}
          onClick={() => setShowFAQs(!showFAQs)}
        >
          <Header sub>
            <Icon name="dropdown" />
            Commonly Asked Questions
          </Header>
        </Accordion.Title>

        <Accordion.Content active={showFAQs}>
          <div id="faqs-content">
            <List>
              {FAQS.map((faq, i) => (
                <List.Item key={i}>
                  <List.Content>
                    <List.Header>{faq.question}</List.Header>
                    <List.Description>{faq.answer}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </div>
        </Accordion.Content>
      </Accordion>
    </div>
  )
}

export default FAQ
