import React, { useState, useEffect, useContext } from 'react'
import { Header, Segment } from 'semantic-ui-react'
import MixpanelTracker from '../../utils/MixpanelTracker'
import DataContext from '../../contexts/DataContext'
import Schedule from '../../components/PublicProject/Schedule'
import ConfirmRegister from '../../components/PublicProject/ConfirmRegister'
import NotFoundPage from '../NotFoundPage'
import ExistingResponseAlert from '../../components/PublicProject/ExistingResponseAlert'
import WorkConfirmation from '../../components/WorkConfirmation'

const Register = ({ project, qualificationResponses }) => {
  const { advisor } = useContext(DataContext)

  const isExistingExpert = advisor.expert !== null
  const hasResponded = advisor.jobs.find(
    (job) =>
      job.project.id === project.id &&
      !['Pending Scheduling', 'Client Reschedule'].includes(job.overall_status)
  )

  const [jobData, setJobData] = useState({
    meetingAt: null,
    qualificationResponses,
  })
  const [page, setPage] = useState(
    hasResponded
      ? 'EXISTING_RESPONSE_ALERT'
      : isExistingExpert
      ? 'WORK_CONFIRMATION'
      : project.type === 'Interview'
      ? 'SCHEDULE'
      : 'CONFIRM_REGISTER'
  )

  useEffect(() => {
    MixpanelTracker.trackViewPublicProjectRegister(advisor, hasResponded)
  }, [advisor, hasResponded])

  const removeSessionStorage = () => {
    sessionStorage.removeItem('publicProject')
    sessionStorage.removeItem('qualification')
  }

  const PAGES = {
    WORK_CONFIRMATION: (
      <Segment>
        <WorkConfirmation
          onNext={() =>
            setPage(
              project.type === 'Interview' ? 'SCHEDULE' : 'CONFIRM_REGISTER'
            )
          }
        />
      </Segment>
    ),
    SCHEDULE: (
      <Schedule
        project={project}
        updateMeetingAt={(meetingAt) => setJobData({ ...jobData, meetingAt })}
        setPage={setPage}
      />
    ),
    CONFIRM_REGISTER: (
      <ConfirmRegister jobData={jobData} project={project} setPage={setPage} />
    ),
    EXISTING_RESPONSE_ALERT: (
      <ExistingResponseAlert project={project} onClose={removeSessionStorage} />
    ),
  }

  return (
    <div>
      <Header>{project.title}</Header>
      {PAGES[page]}
    </div>
  )
}

const PublicProjectRegister = () => {
  const project = JSON.parse(sessionStorage.getItem('publicProject'))
  const qualificationResponses = JSON.parse(
    sessionStorage.getItem('qualification')
  )
  if (!project || !qualificationResponses) {
    return (
      <NotFoundPage message="If you received an invitation to a Dexter project, please go back to the invitation link and start over." />
    )
  }
  return (
    <Register
      project={project}
      qualificationResponses={qualificationResponses}
    />
  )
}

export default PublicProjectRegister
